.OrderingScene_FormSection {
}

.OrderingScene_FormSection-grid {
  display: flex;
  margin: -16px;
}

@media (max-width: 840px) {
  .OrderingScene_FormSection-grid {
    display: block;
    margin: 0;
  }
}

.OrderingScene_FormSection-main {
  flex: 1 1 auto;
  padding: 16px;
  padding-right: 48px;
}

@media (max-width: 840px) {
  .OrderingScene_FormSection-main {
    padding: 0;
  }
}

.OrderingScene_FormSection-side {
  flex: 0 0 360px;
  max-width: 360px;
  padding: 16px;
}

@media (max-width: 840px) {
  .OrderingScene_FormSection-side {
    display: none;
  }
}

.OrderingScene_FormSection-header {
  margin-bottom: 16px;
}
