.Link {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  position: relative;
  font-size: inherit;
  color: inherit;
  margin: 0 -3px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  line-height: 1.5;
  outline: none;
  font-weight: inherit;
  font-family: inherit;
}

.Link::-moz-focus-inner {
  border: 0;
}

.Link:hover {
  color: #666;
}

.Link:active .Link-text::after {
  opacity: 0;
}

.Link--bold {
  font-weight: bold;
}

.Link-icon {
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
}

.Link--inlineIcon .Link-icon {
  line-height: 1;
}

.Link-text {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 0 4px;
  font-weight: inherit;
}

.Link-text::after {
  content: '';
  position: absolute;
  display: block;
  background-color: currentColor;
  opacity: 0.5;
  height: 1px;
  right: 3px;
  left: 3px;
  bottom: 2px;
}

.Link--noUnderline .Link-text::after {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.Link--noUnderline:hover .Link-text::after {
  opacity: 1;
}

.Link--active {
  font-weight: 700;
  color: inherit;
}

.Link--active .Link-text::after {
  opacity: 0;
  transition: none;
}
