.SectionContacts {
  display: flex;
}

@media (max-width: 1100px) {
  .SectionContacts {
    flex-direction: column;
    padding: 40px 16px;
  }
}

@media (max-width: 640px) {
  .SectionContacts {
    padding: 8px 16px;
  }
}

.SectionContacts-questionCol {
  max-width: 400px;
  margin-right: 180px;
}

@media (max-width: 1100px) {
  .SectionContacts-questionCol {
    max-width: none;
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
    margin-right: 0;
  }
}

.SectionContacts-questionItem {
  margin-bottom: 40px;
}

.SectionContacts-questionItem:last-child {
  margin-bottom: 0;
}

@media (max-width: 1100px) {
  .SectionContacts-questionItem {
    flex: 1 1 auto;
  }
}

@media (max-width: 480px) {
  .SectionContacts-questionItem {
    margin-bottom: 32px;
  }
}

.SectionContacts-questionTitle {
  margin: 0;
  margin-bottom: 16px;
  line-height: 1.2;
  font-size: 18px;
  font-weight: 700;
  color: #262931;
}

@media (max-width: 1100px) {
  .SectionContacts-questionTitle {
    margin-bottom: 8px;
  }
}

.SectionContacts-questionDescription {
  margin: 0;
  line-height: 1.6;
  font-size: 16px;
  font-weight: 300;
  color: #444952;
}

.SectionContacts-contactCol {
  max-width: 360px;
}

@media (max-width: 1100px) {
  .SectionContacts-contactCol {
    max-width: none;
    display: flex;
    flex-direction: column;
  }
}

.SectionContacts-contactItem {
  margin-bottom: 40px;
}

.SectionContacts-contactItem:last-child {
  margin-bottom: 0;
}

@media (max-width: 1100px) {
  .SectionContacts-contactItem {
    flex: 1 1 auto;
  }
}

@media (max-width: 480px) {
  .SectionContacts-contactItem {
    margin-bottom: 32px;
  }
}

.SectionContacts-contactTitle {
  margin: 0;
  margin-bottom: 16px;
  line-height: 1.2;
}

@media (max-width: 1100px) {
  .SectionContacts-contactTitle {
    margin-bottom: 8px;
  }
}

.SectionContacts-contactLink {
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  pointer-events: none;
  color: #262931;
}

@media (max-width: 640px) {
  .SectionContacts-contactLink {
    pointer-events: auto;
  }
}

.SectionContacts-contactDescription {
  margin: 0;
  line-height: 1.6;
  font-size: 14px;
  font-weight: 300;
  color: #444952;
}

@media (max-width: 1100px) {
  .SectionContacts-contactDescription {
    font-size: 16px;
  }
}
