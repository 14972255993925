.LandingBG {
  /* position: absolute;
  left: 0;
  right: 0;
  top: 0; */
  height: 880px;
  width: 100%;
  /* background-color: pink; */
  /* z-index: 2; */
  position: relative;
  opacity: 0.15;
}

.LandingBG-item {
  position: absolute;
}

.LandingBG-item:nth-child(1) {
  width: 25px;
  height: 25px;
  top: 25px;
  left: 5%;
}
.LandingBG-item:nth-child(2) {
  top: 60px;
  left: 45%;
  width: 25px;
  height: 25px;
}
.LandingBG-item:nth-child(3) {
  bottom: 60px;
  left: 8%;
  width: 33px;
  height: 32px;
}
.LandingBG-item:nth-child(4) {
  top: 140px;
  right: 20%;
  width: 32px;
  height: 32px;
}
.LandingBG-item:nth-child(5) {
  bottom: 150px;
  right: 10%;
  width: 32px;
  height: 32px;
}
.LandingBG-item:nth-child(6) {
  right: 45%;
  top: 400px;
  width: 15px;
  height: 15px;
}
.LandingBG-item:nth-child(7) {
  left: 13%;
  top: 250px;
  width: 12px;
  height: 12px;
}
.LandingBG-item:nth-child(8) {
  bottom: 70px;
  left: 50%;
  width: 17px;
  height: 17px;
}
.LandingBG-item:nth-child(9) {
  left: 35%;
  top: 550px;
  width: 16px;
  height: 18px;
}
.LandingBG-item:nth-child(10) {
  left: 30%;
  bottom: 200px;
  width: 16px;
  height: 18px;
}
.LandingBG-item:nth-child(11) {
  right: 25%;
  bottom: 300px;
  width: 16px;
  height: 18px;
}
.LandingBG-item:nth-child(12) {
  left: 25%;
  bottom: 450px;
  width: 15px;
  height: 20px;
}
.LandingBG-item:nth-child(13) {
  top: 200px;
  left: 55%;
  width: 17px;
  height: 18px;
}
.LandingBG-item:nth-child(14) {
  left: 40%;
  top: 350px;
  width: 25px;
  height: 25px;
}
