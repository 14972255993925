.Slider {
  height: 6px;
  cursor: pointer;
}

.Slider.disabled {
  cursor: default;
}

.Slider-holder {
  outline: none;
}

.isTabUsing .Slider-holder:focus {
  box-shadow: 0 0 0 2px rgba(47, 124, 192, 0.54);
}

.Slider-bar {
  height: 100%;
}

.Slider-bar-0 {
  background-color: #ffd74a;
  border-radius: 3px 0 0 3px;
}

.Slider.disabled .Slider-bar-0 {
  background-color: #ffeea2;
}

.Slider-bar-1 {
  background-color: #e2e7ee;
  border-radius: 0 3px 3px 0;
}

.Slider.disabled .Slider-bar-1 {
  background-color: #f0f8ff;
}

.Slider-handle {
  width: 25px;
  height: 25px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border-radius: 50%;
  transform: translate(0, -50%);
  top: 50%;
  transition: box-shadow 0.3s ease-out;
  cursor: ew-resize;
  outline: none;
}

.Slider.disabled .Slider-handle {
  cursor: default;
}

.Slider-handle--active {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
}
