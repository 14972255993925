.OrderDetails {
  display: flex;
  justify-content: space-between;
}

.OrderDetails-item {
  box-sizing: border-box;
  flex: 1 1 auto;
  max-width: 420px;
  margin-right: 32px;
}

.OrderDetails--oneCol .OrderDetails-item {
  max-width: none;
}

.OrderDetails-item:last-child {
  margin-right: 0;
}

.OrderDetails-short {
  display: flex;
  font-size: 14px;
  flex-wrap: wrap;
}

.OrderDetails-shortItem {
  flex: 1 1 50%;
  max-width: 50%;
  box-sizing: border-box;
  margin-bottom: 16px;
}

@media (max-width: 640px) {
  .OrderDetails-shortItem {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.OrderDetails-shortTitle {
  font-weight: bold;
}

.OrderDetails-shortValue {
}
