.ProductCardSlider {
  position: relative;
  overflow: hidden;
}

.ProductCardSlider-pagination {
  margin-top: 24px;
}

.ProductCardSlider-paginationItem {
  margin: 4px;
  display: inline-block;
  vertical-align: top;
}

.ProductCardSlider-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 4px;
  border-radius: 4px;
  overflow: hidden;
  cursor: grab;
  justify-content: center;
}

.ProductCardSlider-media {
}

.ProductCardSlider-blurredImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(6px);
  transition: opacity 0.2s ease-out;
  pointer-events: none;
}

.ProductCardSlider-item--withFullImage .ProductCardSlider-blurredImage {
  opacity: 0;
}

.ProductCardSlider-cosmopolitenLabel {
  position: absolute;
  top: -40px;
  left: 0;
  height: 240px;
  z-index: 1000;
  pointer-events: none;
}
