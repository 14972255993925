.ReviewRating {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReviewRating--reverse {
  flex-direction: row-reverse;
}

.ReviewRating-rate {
  margin-right: 8px;
}

.ReviewRating--reverse .ReviewRating-rate {
  margin-right: 0;
  margin-left: 8px;
}

.ReviewRating-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  color: #fed835;
}

.ReviewRating-star {
  fill: currentColor;
  display: flex;
  list-style: none;
}

.ReviewRating--big .ReviewRating-star {
}
