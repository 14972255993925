.CategoryFilterPanel_FilterItem {
  min-width: 320px;
  max-height: 380px;
  overflow-y: auto;
  padding: 16px;
  box-sizing: border-box;
}

.CategoryFilterPanel_FilterItem-option {
}

.CategoryFilterPanel_FilterItem-optionTitle {
}

.CategoryFilterPanel_FilterItem-optionAmount {
  display: inline-block;
  margin-left: 4px;
  font-size: 14px;
  font-weight: 300;
  color: #818690;
  white-space: nowrap;
}
