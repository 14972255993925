.Price {
  display: inline-block;
  color: inherit;
}

.Price--variant-previous {
  color: #818690;
}

.Price--variant-discount {
  color: #e8495a;
}

.Price--variant-mark {
  color: #25bc8b;
}

.Price-number {
}

.Price--variant-previous .Price-number {
  position: relative;
}

.Price--variant-previous .Price-number:after {
  background-color: currentColor;
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 1px;
}

.Price-currency {
}
