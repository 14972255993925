.CardDelivery {
}

.CardDelivery-item {
  margin-bottom: 8px;
  display: inline-flex;
  align-items: center;
}

.CardDelivery-item:last-child {
  margin-bottom: 0;
}

.CardDelivery-text {
  display: inline-block;
  font-size: 14px;
  vertical-align: bottom;
}

.CardDelivery-icon {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}
