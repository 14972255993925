.CallbackConformationDialog {
  padding: 16px 52px 48px;
  max-width: 350px;
}
.CallbackConformationDialog-title {
  margin-top: 32px;
  font-size: 28px;
  line-height: 28px;
  margin-bottom: 16px;
  font-weight: bold;
}

@media (max-width: 840px) {
  .CallbackConformationDialog-title {
    font-size: 24px;
    line-height: 24px;
  }
}

.CallbackConformationDialog-info {
  max-width: 300px;
  margin: 0;
}

.CallbackConformationDialog-form {
  margin: -8px;
}

.CallbackConformationDialog-formItem {
  padding: 8px;
}
